import { utils } from '../../utils/webUtils';
import { window } from '@wix/photography-client-lib';

export default class AccessibilityHelper {
  constructor(galleryWrapper, props) {
    this.galleryWrapper = galleryWrapper;
    this.galleryWrapperProps = props;

    this.update = this.update.bind(this);
    this.initAccessibility = this.initAccessibility.bind(this);
    this.cleanupAccessibility = this.cleanupAccessibility.bind(this);
    this.waitForFirstTabPress = this.waitForFirstTabPress.bind(this);

    this.isDevAccessibility = utils.shouldDebug('accessibility');
    this.tabWasPressed = false;
  }

  update(props) {
    this.galleryWrapperProps = props;

    if (
      this.galleryWrapper.state.isAccessible !==
      ((this.galleryWrapperProps.accessibilityEnabled ||
        this.isDevAccessibility) &&
        this.tabWasPressed)
    ) {
      this.galleryWrapper.setState({
        isAccessible:
          (this.galleryWrapperProps.accessibilityEnabled ||
            this.isDevAccessibility) &&
          this.tabWasPressed,
      });
    }
  }

  initAccessibility() {
    if (
      this.galleryWrapperProps.accessibilityEnabled ||
      this.isDevAccessibility
    ) {
      window.addEventListener('keydown', this.waitForFirstTabPress);
    }
  }

  cleanupAccessibility() {
    if (
      this.galleryWrapperProps.accessibilityEnabled ||
      this.isDevAccessibility
    ) {
      window.removeEventListener('keydown', this.waitForFirstTabPress);
    }
  }

  waitForFirstTabPress = (event) => {
    if (event.keyCode === 9) {
      event.preventDefault();
      window.removeEventListener('keydown', this.waitForFirstTabPress);
      this.tabWasPressed = true;
      this.galleryWrapper.setState({
        isAccessible: true,
      });
      return false;
    }
  };
}
