import SentryReporter from './SentryReporter';

export default class Context {
  constructor(SENTRY_DSN) {
    this.context = null;
    this.sentryReporter = new SentryReporter(SENTRY_DSN);
  }

  setContext(context) {
    this.context = context;
  }

  initSentry(initAppParam, scopedGlobalSdkApis, platformServices) {
    this.sentryReporter.init(
      initAppParam,
      scopedGlobalSdkApis,
      platformServices,
    );
  }

  getSentry() {
    return this.sentryReporter;
  }

  getContext() {
    return this.context;
  }
}
