const {PublishMethods, manager: webBiLoggerManager, factory} = require('@wix/web-bi-logger/dist/src/logger')
const {create, phasesConfig} = require('@wix/fedops-logger')
const {presetsTypes} = require('@wix/fedops-presets')

const BOLT_APP_NAME = 'bolt-viewer'
const DS_APP_NAME = 'bolt-ds'

const events = {
    bolt: {
        14: 21,
        11: 23,
        12: 28,
        13: 22,
        15: 24,
        16: 25,
        17: 26,
        18: 27
    },
    ds: {
        14: 31,
        11: 33,
        12: 38,
        13: 32,
        15: 34,
        16: 35,
        17: 36,
        18: 37
    }
}

const webBiLoggers = []

webBiLoggerManager.onLoggerCreated(logger => {
    webBiLoggers.push(logger)
})

const isDS = () => window && window.documentServicesModel

const paramsConverter = params => {
    const ds = isDS()
    const map = ds ? events.ds : events.bolt
    if (params.evid in map) {
        params.evid = map[params.evid]
    }
    return {type: 'reportBI', ...params}
}

const serverLoggerFactory = (biStore, shouldBeMutedForFedOps = () => false) => factory({
    publishMethod: PublishMethods.PostMessage
}).setMuted(shouldBeMutedForFedOps())
    .withUoUContext({
        msid: biStore.msid,
        visitorId: biStore.visitorId,
        siteMemberId: () => biStore.siteMemberId
    })
    .updateDefaults({
        is_sav_rollout: biStore.is_sav_rollout,
        is_dac_rollout: biStore.is_dac_rollout,
        is_rollout: biStore.is_rollout,
        is_cached: biStore.is_cached,
        dc: biStore.data_center,
        ish: biStore.is_headless
    })
    .withTransformer({
        [PublishMethods.PostMessage]: paramsConverter
    })

const clientLoggerFactory = (biStore, shouldBeMutedForFedOps = () => false) => factory({
    publishMethod: PublishMethods.Auto,
    useBatch: true
})
    .setMuted(shouldBeMutedForFedOps())
    .withUoUContext({
        msid: biStore.msid,
        visitorId: biStore.visitorId,
        siteMemberId: () => biStore.siteMemberId
    })
    .updateDefaults({
        is_rollout: biStore.is_rollout,
        ...(isDS() ? {//eslint-disable-line no-extra-parens
            ts: () => Date.now() - biStore.initialTimestamp,
            pages_count: biStore.pages_count,
            origin: biStore.origin
        } : {
            is_cached: biStore.is_cached,
            dc: biStore.data_center,
            ish: biStore.is_headless,
            is_sav_rollout: biStore.is_sav_rollout,
            is_dac_rollout: biStore.is_dac_rollout,
            vsi: biStore.viewerSessionId
        })
    })
    .withTransformer(paramsConverter)

const getFedOpsServerLogger = (biStore, muteFunc) => create(BOLT_APP_NAME, {
    presetType: presetsTypes.BOLT,
    isServerSide: true,
    biLoggerFactory: serverLoggerFactory(biStore, muteFunc),
    phasesConfig: phasesConfig.SEND_ON_FINISH
})

const getFedOpsClientLogger = (biStore, muteFunc) => create(isDS() ? DS_APP_NAME : BOLT_APP_NAME, {
    presetType: isDS() ? presetsTypes.DS : presetsTypes.BOLT,
    isServerSide: false,
    reportBlackbox: true,
    biLoggerFactory: clientLoggerFactory(biStore, muteFunc),
    phasesConfig: phasesConfig.SEND_ON_FINISH
})

const flushAllFedOpsLoggers = async () => Promise.all(webBiLoggers.map(logger => logger.flush()))

module.exports = {
    getFedOpsClientLogger,
    getFedOpsServerLogger,
    flushAllFedOpsLoggers,
    webBiLoggerFactory: (...args) => factory(...args)
}
