export default class SentryReporter {
  constructor(SENTRY_DSN) {
    this.SENTRY_DSN = SENTRY_DSN;
    this.sentry = null;
  }
  init(initAppParam, scopedGlobalSdkApis, platformServices) {
    // this.platformServices = platformServices;

    const context = {
      id: initAppParam.instanceId,
      url: scopedGlobalSdkApis.location.baseUrl,
    };
    const SENTRY_DSN = this.SENTRY_DSN;
    this.sentry = platformServices.monitoring.createMonitor(
      SENTRY_DSN,
      data => {
        data.environment = 'Worker';
        return data;
      },
    );

    this.sentry.setUserContext(context);
  }

  report(e) {
    if (this.sentry) {
      this.sentry.captureException(e);
    } else {
      console.error('SentryReporter: trying to report before init', e);
    }
  }
}
