class directFullscreenHelper {
  //constructor() {}

  getDirectFullscreenFromParams(queryParams) {
    const fullscreenParam = queryParams && queryParams.pgid;
    if (fullscreenParam) {
      const index = fullscreenParam.indexOf('-');
      const compId = 'comp-' + fullscreenParam.substring(0, index);
      const itemId = fullscreenParam.substring(
        index + 1,
        fullscreenParam.length,
      );
      return (
        compId &&
        itemId && {
          compId,
          itemId,
        }
      );
    } else {
      return false;
    }
  }

  getDirectFullscreenItemFromItemsList(queryParams, items) {
    const directfullscreenParams = this.getDirectFullscreenFromParams(
      queryParams,
    );
    if (directfullscreenParams && items) {
      return items[
        items.findIndex(item => item.itemId === directfullscreenParams.itemId && !item.metaData.isVideoPlaceholder)
      ];
    } else {
      return undefined;
    }
  }

  getDirectFullscreenPromise(
    galleryCompId,
    queryParams,
    proGalleryStore,
    isSSR,
  ) {
    const directfullscreenParams = this.getDirectFullscreenFromParams(
      queryParams,
    );
    if (
      directfullscreenParams &&
      galleryCompId === directfullscreenParams.compId
    ) {
      return proGalleryStore.loadDirectFullscreen(
        directfullscreenParams.itemId,
        isSSR,
      );
    } else {
      return Promise.resolve(0);
    }
  }

  setMetaTagsForItemIfNeeded(props, item) {
    const isInSEO = props.isInSEO;
    if (item && isInSEO) {
      const type = 'image'; // TODO - connect the real deal. could be galleryItem.js L1035 - and understand how it matters? maybe i dont need this one.
      const dto = item.metaData;
      props.setMetaTags && props.setMetaTags(type, dto);
    }
  }
}
export default new directFullscreenHelper();
