import React from 'react';
import { PG_TEST_TYPE } from '../constants';

export default class ProGalleryTestIdentifier extends React.Component {
  createTestDescriptionElement() {
    let testColor = 'blue';
    let message = '_ DEFAULT MESSAGE';
    const testType = this.props.testType;
    switch (testType) {
      case PG_TEST_TYPE.USER_SSR:
        message = '_ USER-VIEW SSR';
        testColor = 'navy';
        break;
      case PG_TEST_TYPE.BOT_SSR:
        message = '_ BOT-VIEW SSR';
        testColor = 'rosybrown';
        break;
      case PG_TEST_TYPE.USER_CLIENT:
        message = '_ USER-VIEW CLIENT';
        testColor = 'mediumslateblue';
        break;
      case PG_TEST_TYPE.PRODUCTION_USER_SSR:
        message = '_ USER-VIEW PRODUCTION SSR';
        testColor = 'red';
        break;
      case PG_TEST_TYPE.PRODUCTION_USER_CLIENT:
        message = '_ USER-VIEW PRODUCTION CLIENT';
        testColor = 'orangered';
        break;
      default:
        return null;
    }
    return (
      <div
        class="test-pg"
        style={{
          fontFamily: 'Sans-Serif',
          boxShadow: `inset -15px 0 0 ${testColor}, inset 0 -15px 0 ${testColor}, inset 15px 0 0 ${testColor}, inset 0 15px 0 ${testColor}`,
          height: '100%',
          width: '100%',
          position: 'absolute',
          zIndex: '2000',
          color: testColor,
          fontSize: '50px',
        }}
      >
        {message}
      </div>
    );
  }

  render() {
    const isNeeded =
      typeof this.props.testType === 'number' ||
      typeof this.props.testType === 'string';
    return isNeeded ? this.createTestDescriptionElement() : null;
  }
}
